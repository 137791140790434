import React from 'react';
import { colors, mq, fontSizes, fontWeights } from '../theme';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Container from '../components/Container';
import {
  Heading2,
  Paragraph,
  HeadingDivider
} from '../components/Heading/Text';
import BackgroundImage from '../components/BackgroundImage/BackgroundImage';
import WebsiteButton from '../components/WebsiteButton';
import TickItem from '../components/TickItem';
import ContainerImage from '../components/ContainerImage/ContainerImage';
import FooterBanner from '../components/FooterBanner/FooterBanner';

const PricingPage = () => {
  return (
    <Layout>
      <SEO
        title="Nos tarifs pour les pros du bâtiment"
        description="Web2Vi vous propose plusieurs solutions d'abonnements à notre logiciel de gestion. Prix à l'année ou mensualisé, découvrez ce que contiennent nos différentes formules."
      />
      <Container
        background={colors.neutralWhite}
        css={mq({
          zIndex: 1,
          paddingBottom: [0, 0],
          paddingTop: 0,
          minHeight: [550, 400, 500],
          position: 'relative',
          alignItems: 'center',
          justifyContent: ['inherit', 'center']
        })}
      >
        <div css={mq({ width: ['70%', '100%'], paddingBottom: 40 })}>
          <Heading2 primary light>
            Tout ce dont vous avez besoin
            <br /> à un
            <span> tarif ultra attractif</span>
          </Heading2>
          <Paragraph css={{ marginTop: 30 }}>
            Accédez à toutes les fonctionnalités de notre logiciel pour le prix
            mensuel d’une paire de chaussures de sécurité d’entrée de gamme.
          </Paragraph>
          <WebsiteButton
            href="https://info.web2vi.com/contact"
            variant="outline"
            mt={8}
          >
            En savoir plus sur les tarifs
          </WebsiteButton>
        </div>
        <div
          css={mq({
            display: ['block', 'none', 'none'],
            position: ['absolute', 'relative'],
            bottom: 0,
            right: [-120, 0],
            width: 800,
            height: [550, 500, 400]
          })}
        >
          <BackgroundImage
            src="kevin.png"
            css={mq({
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'bottom center'
            })}
          />
        </div>
      </Container>

      <Container
        padding={false}
        background={colors.neutralDarker}
        css={{ padding: '30px 0' }}
      >
        <div
          css={mq({
            width: ['50%', '80%'],
            textAlign: 'center',
            margin: ['0px auto', '20px auto']
          })}
        >
          <div
            css={{
              padding: 30,
              fontWeight: fontWeights.semiBold,
              color: colors.neutralWhite
            }}
          >
            <div
              css={{
                color: colors.brandColor,
                fontSize: fontSizes.larger,
                textTransform: 'uppercase',
                lineHeight: '30px',
                letterSpacing: 1
              }}
            >
              à partir de
            </div>
            <div
              css={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'center',
                fontSize: 110,
                lineHeight: '130px'
              }}
            >
              33
              <span css={{ fontSize: 50, lineHeight: '90px' }}>€</span>
            </div>
            <div css={{ fontSize: fontSizes.large }}>HT par mois</div>
            <WebsiteButton
              href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
              trackLocation="Tarifs - Pricing 33"
              mt={16}
              width="80%"
            >
              demander une démo
            </WebsiteButton>
          </div>
        </div>
      </Container>

      <p
        css={mq({
          backgroundColor: colors.brandColor,
          textAlign: ['center', 'left'],
          margin: `0 auto`,
          padding: '40px 19px',
          lineHeight: '48px',
          color: colors.neutralDarker,
          fontWeight: fontWeights.semiBold,
          fontSize: fontSizes.larger
        })}
      >
        Web2vi <span css={{ color: colors.neutralWhite }}>comprend</span>
      </p>

      <ContainerImage
        source="page-tarifs-section-1.png"
        background={colors.neutralWhite}
      >
        <Heading2 light>Devis rapides et précis avec&nbsp;:</Heading2>
        <HeadingDivider />
        <div>
          <TickItem>Déboursés et prix de vente simultanés</TickItem>
          <TickItem>Variantes en un clic</TickItem>
          <TickItem>Points morts et marges</TickItem>
          <TickItem>Décomposition du prix de vente</TickItem>
          <TickItem>Multi TVA et remises</TickItem>
          <TickItem>Gestion des statuts des devis</TickItem>
          <TickItem>Envoi par mail à partir de la plateforme</TickItem>
        </div>
        <WebsiteButton
          href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
          trackLocation="Tarifs - Module 1"
          mt={8}
          width="250px"
        >
          demander une démo
        </WebsiteButton>
      </ContainerImage>

      <ContainerImage
        direction="left"
        source="page-tarifs-section-2.png"
        background={colors.neutralLighter}
      >
        <Heading2 light>Facturation</Heading2>
        <HeadingDivider />
        <div>
          <TickItem>Acomptes et avoirs</TickItem>
          <TickItem>Factures de situation</TickItem>
          <TickItem>Envoi par mail à partir de la plateforme</TickItem>
        </div>
        <WebsiteButton
          href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
          trackLocation="Tarifs - Module 2"
          mt={8}
          width="250px"
        >
          demander une démo
        </WebsiteButton>
      </ContainerImage>

      <ContainerImage
        source="page-tarifs-section-3.png"
        background={colors.neutralWhite}
      >
        <Heading2 light>
          Gestion <span>de chantier</span>
        </Heading2>
        <HeadingDivider />
        <div>
          <TickItem>Demandes de prix aux négoces</TickItem>
          <TickItem>Bons de commandes matériaux</TickItem>
          <TickItem>Feuilles de déboursé</TickItem>
          <TickItem>Fiches d’instruction et de temps chantier</TickItem>
          <TickItem>Gestion des heures chantier</TickItem>
          <TickItem>Envoi par mail à partir de la plateforme</TickItem>
        </div>
        <WebsiteButton
          href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
          trackLocation="Tarifs - Module 3"
          mt={8}
          width="250px"
        >
          demander une démo
        </WebsiteButton>
      </ContainerImage>

      <ContainerImage
        direction="left"
        source="page-tarifs-section-4.png"
        background={colors.neutralLighter}
      >
        <Heading2 light>
          Bibliothèque <span>d’ouvrages et matériaux</span>
        </Heading2>
        <HeadingDivider />
        <div>
          <TickItem>Compatible avec les DTU</TickItem>
          <TickItem>Avec temps main d‘oeuvre</TickItem>
          <TickItem>Détail des fournitures</TickItem>
          <TickItem>Textes devis et textes technique</TickItem>
          <TickItem>Tarifs matériaux et gestion des remises</TickItem>
        </div>
        <WebsiteButton
          href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
          trackLocation="Tarifs - Module 4"
          mt={8}
          width="250px"
        >
          demander une démo
        </WebsiteButton>
      </ContainerImage>
      <FooterBanner />
    </Layout>
  );
};

export default PricingPage;
